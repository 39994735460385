// const { each } = require("jquery");

function scrollToo ($e, duration, y) {
    y = y || 0;
    duration = duration || 300;
    let _top = $e.offset().top + y;
    $('html,body').animate({"scrollTop" : _top}, duration);
    return false;
}

function placeholder () {
    let fields = $('.forma__field .forma__field__itext, .forma__field textarea');

    fields.each(function() {
        if ($(this).val() != '' && $(this).val() != $(this).parent().find('label').text()) $(this).parent().find('label').hide();
    });

    fields.on('keyup', function () {
        if ($(this).val() != '' && $(this).val() != $(this).parent().find('label').text()) $(this).parent().find('label').hide();
        else $(this).parent().find('label').show();
    }).on('focusin', function () {
        $(this).parent().addClass('forma__inp_light');
    }).on('focusout', function () {
        $(this).parent().removeClass('forma__inp_light');
    });
}

/*********************************
********* + Menu mobile **********
*********************************/

function menuToggle () {
    let menu = $('#mm');
    let toggleBut = $('.menuToggle');
    let plusBut = $('#mm .mm__parent__arrow');

    toggleBut.on('click', function () {
        if (menu.css('display') == 'block') {
            //menu.animate({'opacity': 0}, 300, function() { $(this).hide(); });
            menu.slideUp(300, 'linear');
            toggleBut.removeClass('active');
        }
        else {
            menu.slideDown(300, 'linear');
            menu.show().animate({'opacity': 1}, 300);
            toggleBut.addClass('active');
        }
    });

    plusBut.on('click', function (event) {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $(this).parent().next().slideUp(300, 'linear');
        } else if (!$(this).parent().next().is('.subs:animated') && !$(this).parent().next().is('.subs__column__item__list:animated')) {
            $(this).addClass('active');
            $(this).parent().next().slideDown(300, 'linear');
        }

        event.preventDefault();
    });

    $(window).on('resize', function() {
        setTimeout(function() {
            if (window.innerWidth >= 769) {
                menu.show();
                menu.find('.active').each(function() {
                    $(this).removeClass('active');
                    $(this).parent().next().css('display', 'block');
                });
                menu.find('.subs, .subs__column__item__list').each(function() {
                    $(this).show();
                });
            } else if (!toggleBut.hasClass('active')) menu.hide();
        }, 50);
    });

    $(document).on('click touchstart', function(event) {
		var $target = $(event.target);
        if (!$target.is(menu) && !menu.find($target).length && !$target.is(toggleBut) && !toggleBut.find($target).length && toggleBut.hasClass('active')) toggleBut.trigger('click');
    });
}

let totop = function () {
    totop.$but = $('.totop');
    totop.$window = $(window);
    totop.$scrollEl = $('header');

    totop.duration = 400;

    totop.showBut();
    totop.windowEvents();
    totop.scrollToTop();
}

totop.scrollToTop = function () {
    totop.$but.click(function () {
        $('html,body').animate({'scrollTop' : 0}, totop.duration);
    });
}

totop.calcHeightDoc = function () {
	var scrollHeight = Math.max(
		document.body.scrollHeight, document.documentElement.scrollHeight,
		document.body.offsetHeight, document.documentElement.offsetHeight,
		document.body.clientHeight, document.documentElement.clientHeight
	);

	return scrollHeight;
}

totop.showBut = function () {
	var _heightWin = document.documentElement.clientHeight + totop.$scrollEl.height();
	var _top = totop.$scrollEl.offset().top + totop.$scrollEl.height();
	//console.log(totop.calcHeightDoc()+' '+_heightWin)
    if (totop.$window.scrollTop() >= _top && totop.calcHeightDoc() > _heightWin) totop.$but.addClass('vis');
    else totop.$but.removeClass('vis');
}

totop.windowEvents = function () {
    totop.$window.on('scroll', function () {
        setTimeout(function () {
            totop.showBut();
        }, 50);
    });

    totop.$window.on('resize', function () {
        setTimeout(function () {
            totop.showBut();
        }, 50);
    });
}

function fixPanel () {
    let openBut = $('.head__openFixPanel');
    let panel = $('.fixPanel');
    let closeBut = panel.find('.icon-cross');

    openBut.on('click', function() {
        panel.addClass('open');
    });

    closeBut.on('click', function() {
        panel.removeClass('open');
    });

    $(document).on('click touchstart', function(event) {
		var $target = $(event.target);
        if (!$target.is(panel) && !panel.find($target).length && !$target.is(openBut) && !openBut.find($target).length && panel.hasClass('open')) closeBut.trigger('click');
    });
}

function carousel () {
	$('.owl-carousel-commodity').each(function() {
        $(this).owlCarousel({
            // stagePadding: 50,
            loop:true,
            margin:30,
            responsiveClass:true,
            responsive:{
                0:{
                    items:1,
                    nav:true
                },
                401:{
                    items:2,
                    nav:true
                },
                640:{
                    items:3,
                    nav:true
                },
                768:{
                    items:4,
                    nav:true
                }/*,
                1000:{
                    items:5,
                    nav:true
                }*/
            }
        });
    });
    
    var owl = $('.slider__box');
    owl.owlCarousel({
        items:1,
        loop:true,
        margin:0,
        autoplay:true,
        nav:true,
        autoplayTimeout:8000,
        autoplayHoverPause:true
    });

    var owl = $('.opinions__box');
    owl.owlCarousel({
        items:1,
        loop:true,
        margin:0,
        autoplay:true,
        nav:true,
        autoplayTimeout:8000,
        autoplayHoverPause:true
    });
}

function search () {
    let search = $('.search');
    let itext = search.find('.forma__field__itext');
    let but = search.find('.search__but');
    let icon = $('#iconSearch');

    icon.on('click', function() {
        !$(this).next().hasClass('vis') ? $(this).next().addClass('vis') : but.trigger('click');
    });

    itext.on('keyup mouseup', function() {
        if ($(this).val() != '' && $(this).val() != $(this).prev().text()) {
            $(this).addClass('active');
            but.prop('disabled', false);
        } else {
            $(this).removeClass('active');
            but.prop('disabled', true);
        }
    });

    $(document).on('click touchstart', function(event) {
		let $target = $(event.target);
        if (!$target.is(search) && !search.find($target).length && !$target.is(but) && !itext.hasClass('active')) icon.next().removeClass('vis');
    });
}

let filtersCatalog = function () {
    filtersCatalog.forma = $('.filters form');
    filtersCatalog.body = $('#main');
	filtersCatalog.resetBut = $('.filters .filters__reset span');
    filtersCatalog.showMore = $('.filters__item__more span');
    filtersCatalog.hideMore = $('.filters__item__hide span');
    filtersCatalog.openFilters = $('.filters .filters__caption div');
    filtersCatalog.sortingSelect = $('.sorting select');
    filtersCatalog.sortingInp = $('#sort');

    filtersCatalog.requestNow = 0;
    filtersCatalog.shadow = 0;
    filtersCatalog.timeout = 0;
    filtersCatalog.shadowTmpl = '<div class="shadow-request"></div>';

    filtersCatalog.keypressSlider = 0;
    filtersCatalog.rangeStart =  Number($('meta[name="catalog:cost-min"]').attr('content'));
    filtersCatalog.rangeEnd =  Number($('meta[name="catalog:cost-max"]').attr('content'));
    filtersCatalog.rangeMin =  Number($('meta[name="catalog:cost-min"]').attr('content'));
    filtersCatalog.rangeMax =  Number($('meta[name="catalog:cost-max"]').attr('content'));
	filtersCatalog.sliderChange = 0;
	
	filtersCatalog.inputMin = document.getElementById('costMin');
    filtersCatalog.inputMax = document.getElementById('costMax');

    filtersCatalog.forma.find('input:checkbox, input:radio').change(function () {
        if (!filtersCatalog.requestNow) filtersCatalog.submitForm();
    });

    filtersCatalog.resetBut.click(function () {
		window.location = window.location.href.split('?')[0];
    });

    if ($('#costMin').length) filtersCatalog.range = 1;

    filtersCatalog.exec();
    filtersCatalog.sorting();
    filtersCatalog.showMoreInfo();
}

filtersCatalog.resetSliderRange = function () {
    filtersCatalog.rangeStart = filtersCatalog.rangeMin;
    filtersCatalog.rangeEnd = filtersCatalog.rangeMax;
    filtersCatalog.keypressSlider.noUiSlider.reset();
}

filtersCatalog.resetForm = function (urlParamsObject) {
    var counter = 0;
    for (var key in urlParamsObject) {
        counter++;
    }
    if (counter > 1 || filtersCatalog.sliderChange) filtersCatalog.resetBut.show();
}

filtersCatalog.shadowShow = function () {
    if (filtersCatalog.shadow) filtersCatalog.shadow.css('visibility', 'visible');
    else {
        filtersCatalog.body.prepend(filtersCatalog.shadowTmpl);
        filtersCatalog.shadow = $('.shadow-request');
        filtersCatalog.shadow.css('visibility', 'visible');
    }
}

filtersCatalog.submitForm = function () {
    filtersCatalog.requestNow = 1;
    setTimeout(function () {
        //filtersCatalog.shadowShow();
        filtersCatalog.requestNow = 0;
        filtersCatalog.forma.submit();
    }, 1000);
}

filtersCatalog.checkboxEvents = function (urlParamsObject) {
    $.each(urlParamsObject, function(name, value) {
        if( typeof value !== 'object' ) {
            filtersCatalog.forma.find('[name = "'+decodeURIComponent(name)+'"][value = "'+decodeURIComponent(value)+'"]').prop('checked', true);
        } else {
            $.each(filtersCatalog.forma.find('[name = "'+decodeURIComponent(name)+'"]'), function() {
                if (value.indexOf($(this).val()) !== -1) $(this).prop('checked', true);
            });
        }
        //filtersCatalog.forma.find('[name = "'+decodeURIComponent(name)+'"][value = "'+decodeURIComponent(value)+'"]').click();
    });
}

filtersCatalog.setSliderParams = function (urlParamsObject) {
	/*var start = urlParamsObject[filtersCatalog.inputMin.name];
	var end = urlParamsObject[filtersCatalog.inputMax.name];

    if (start != filtersCatalog.rangeStart || end != filtersCatalog.rangeEnd) filtersCatalog.sliderChange = 1;

    filtersCatalog.keypressSlider.noUiSlider.set([start, end]);*/
    var start = urlParamsObject[filtersCatalog.inputMin.name];
    var end = urlParamsObject[filtersCatalog.inputMax.name];

    start = start.replace(/\+/g, '');
    end = end.replace(/\+/g, '');

    if (start != filtersCatalog.rangeStart || end != filtersCatalog.rangeEnd) filtersCatalog.sliderChange = 1;

    filtersCatalog.keypressSlider.noUiSlider.set([start, end]);
}

filtersCatalog.sendSliderRange = function () {
    clearTimeout(filtersCatalog.timeout);

    filtersCatalog.timeout = setTimeout(function () {
        if (!filtersCatalog.requestNow) filtersCatalog.submitForm();
    }, 1000);
}

filtersCatalog.getAllUrlParams = function () {
    /*var vars = window.location.search.match(new RegExp('[^&?]+', 'gm'));
	var result = {};

    if (vars) {
        for (var i=0; i < vars.length; i++) {
            var r = vars[i].split('=');
			result[r[0]] = r[1];
        }
	}

    return result;*/
    var uri = decodeURI(location.search.substring(1));
    var vars = uri.split('&');
    var result = {};

    if (uri) {
        for (var i=0; i < vars.length; i++) {
            var r = vars[i].split('=');
            //result[r[0]] = r[1];
            if(r[0].search("\\[\\]") !== -1) {
                typeof result[r[0]] === 'undefined' ? result[r[0]] = [r[1]] : result[r[0]].push(r[1]);
            } else {
                result[r[0]] = r[1];
            }
        }
    }

    return result;
}

filtersCatalog.exec = function () {
    //var countKey = 0;

    if (filtersCatalog.range) filtersCatalog.sliderRange(filtersCatalog.rangeStart, filtersCatalog.rangeEnd, filtersCatalog.rangeMin, filtersCatalog.rangeMax);

    var urlParamsObject = filtersCatalog.getAllUrlParams();

	// $.each(urlParamsObject, function(name, value) {
    //     filtersCatalog.forma.find('[name = "'+decodeURIComponent(name)+'"][value = "'+decodeURIComponent(value)+'"]').prop('checked', true);
    //     countKey += 1;
	// });
	
    // if (countKey > 1) {
	// 	filtersCatalog.setSliderParams(urlParamsObject);
	// 	filtersCatalog.resetForm();
    // }
    
    if (!$.isEmptyObject(urlParamsObject)) {
        filtersCatalog.checkboxEvents(urlParamsObject);
        if (filtersCatalog.range) filtersCatalog.setSliderParams(urlParamsObject);
        filtersCatalog.resetForm(urlParamsObject);
        //filtersCatalog.checkChangeForm();
    }
}

filtersCatalog.showMoreInfo = function () {

	filtersCatalog.showMore.click(function() {
        $(this).parents('.filters__item').addClass('vis');
		// $(this).parents('.filters__item').hasClass('vis') ? $(this).parents('.filters__item').removeClass('vis') : $(this).parents('.filters__item').addClass('vis');
    });
    
    filtersCatalog.hideMore.click(function() {
        $(this).parents('.filters__item').removeClass('vis');
    });

    filtersCatalog.forma.find('.filters__item__overflow').each(function() {
        if ($(this).find('input:checked').length) $(this).parent().find('.filters__item__more span').click();
    });

    filtersCatalog.openFilters.click(function() {
		$(this).parents('.filters').hasClass('vis') ? $(this).parents('.filters').removeClass('vis') : $(this).parents('.filters').addClass('vis');
	});
}

filtersCatalog.sorting = function () {
    var sortingValue;

    sortingValue = filtersCatalog.sortingInp.val();
    filtersCatalog.sortingSelect.find('[value = '+sortingValue+']').attr('selected', true);

    filtersCatalog.sortingSelect.change(function() {
        sortingValue = $(this).val();
        filtersCatalog.sortingInp.val(sortingValue);
        if (!filtersCatalog.requestNow) filtersCatalog.submitForm();
    })
}

filtersCatalog.sliderRange = function (start, end, min, max) {//my parametres
    filtersCatalog.keypressSlider = document.getElementById('costSlider');
    var inputs = [filtersCatalog.inputMin, filtersCatalog.inputMax];
    var stepSlider = Number($('meta[name="catalog:cost-step"]').attr('content'));
    var timeout;

    noUiSlider.create(filtersCatalog.keypressSlider, {
    	start: [start, end],
    	connect: true,
        step: stepSlider,
    	range: {
    		'min': [min],
    		'max': max
    	},
        format: wNumb({
    		decimals: 0,
	        thousand: ' '
    	})
    });

    filtersCatalog.keypressSlider.noUiSlider.on('update', function( values, handle ) {
        // console.log(values[1])
    	inputs[handle].value = values[handle];
    });

    filtersCatalog.keypressSlider.noUiSlider.on('change', function () {
        filtersCatalog.sendSliderRange(); //my function
    });

    function setSliderHandle(i, value) {
    	var r = [null,null];
    	r[i] = value;
    	filtersCatalog.keypressSlider.noUiSlider.set(r);
    }

    // Listen to keydown events on the input field.
    inputs.forEach(function(input, handle) {

    	input.addEventListener('change', function(){
            setSliderHandle(handle, this.value);
            //console.log(this.value)
            //window.location = window.location.href.split('?')[0];
            filtersCatalog.sendSliderRange(); //my function
    	});
    });
}

// var cart = new Cart();

var shop2 = function () {
    shop2.container = $('.cart');
    shop2.items = $('.cart__desktop').find('.cartItem');
    shop2.buttons = $('.cartTable__calc__buttons');
    shop2.delButton = $('.cart .icon-cross');
    shop2.deliveryInputs = $('.cart .forma__field_delivery input');
    shop2.cartIcon = $('#head .head__cart span');
    shop2.urlCart = $('meta[name="cart:url"]').attr('content');
    shop2.art = 'inputArt';
    shop2.price = 'inputCost';
    shop2.amount = 'cartTable__calc__itext';
    shop2.sum = 'cartTable__sum';
    shop2.totalSum = 'cartTable__total';
    shop2.tmpl = '<h3>Корзина пуста</h3>';
    shop2.products = [];
    shop2.currency = '₽';

    shop2.createProducts();
    shop2.exec();
    shop2.calcTotalSum();
    shop2.events();
}

shop2.calcTotalSum = function () {
    var sum = 0;

    for(var key in shop2.products) {
        sum += shop2.products[key].cost * shop2.products[key].amount;
        shop2.container.find('.'+shop2.totalSum).each(function() {
            $(this).text(sum+' '+shop2.currency);
        });
    }
}

shop2.calcSum = function (key) {
    var sum = shop2.products[key].cost * shop2.products[key].amount;
    return sum;
}

shop2.createProducts = function () {
    shop2.items.each(function() {
        var id = $(this).data('id');
        var art = $(this).find('.'+shop2.art).val();
        var amount = $(this).find('.'+shop2.amount).val();
        var cost = $(this).find('.'+shop2.price).val();
        shop2.products[id] = {
            'art' : art,
            'amount' : amount,
            'cost' : cost
        };
    });
}

shop2.update = function (key, amount) {
    shop2.products[key].amount = amount;
    var sum = shop2.calcSum(key);

    shop2.container.find('[data-id = '+key+']').each(function() {
        $(this).find('.'+shop2.sum).text(sum+' '+shop2.currency);
    });
    shop2.calcTotalSum();
}

/*shop2.emptyCart = function() {
    shop2.container.empty().html(shop2.tmpl);
}*/

shop2.deleteProduct = function (id) {
    cart.delProduct(id);
    if (cart.products.length == 0) location.reload();
    cart.push(shop2.urlCart, cart.products, function (data) {
        delete shop2.products[id];
        shop2.container.find('[data-id = '+id+']').each(function() {
            $(this).remove();
            if (cart.products.length) {
                shop2.calcTotalSum();
                shop2.cartIcon.text(cart.products.length);
            }
            //!$.isEmptyObject(shop2.products) ? shop2.calcTotalSum() : shop2.emptyCart();
        });
        //Cart.calcSumTotal();
        //shop2.$total.text(data.formatTotal + ' ' + shop2.currency.name);
    });
    /*var data = {
        id: key,
        art: shop2.products[key].art,
        cost: shop2.products[key].cost,
        amount: shop2.products[key].amount
    }
    $.ajax({
        method : 'post',
        url: shop2.urlCart,
        data: data,
        success: function(data) {
            if (data.cart) {
                delete shop2.products[key];
                shop2.container.find('[data-id = '+key+']').each(function() {
                    $(this).remove();
                    !$.isEmptyObject(shop2.products) ? shop2.calcTotalSum() : shop2.emptyCart();
                });
            }
        }
    });*/
}

shop2.exec = function () {
    for(var key in shop2.products) {
        var sum = shop2.calcSum(key);
        // var sum = shop2.products[key].cost * shop2.products[key].count;
        shop2.container.find('[data-id = '+key+']').each(function() {
            $(this).find('.'+shop2.sum).text(sum+' '+shop2.currency);
        });
    }
}

shop2.events = function () {
    var allowedRegex = /^[0-9]+$/;
    var allowedCharRegex = /[0-9]/;
    
    shop2.buttons.find('.cartTable__calc__plus, .cartTable__calc__minus').on('click', function () {
        var val = parseInt($(this).parent().find('.cartAmount').val());
        var amount = $(this).hasClass('cartTable__calc__plus') ? val + 1 : val - 1;
        if (amount < 1) amount = 1;

        var id = $(this).parents('.cartItem').data('id');
        shop2.container.find('[data-id = '+id+']').each(function() {
            $(this).find('.cartAmount').val(amount);
        });

        shop2.update(id, amount);
        //cloneThis.calcSum($(this).parents('tr'));
    });

    shop2.buttons.find('.cartAmount').on('paste', function(e) {
        // handle only copy paste here
        // get clipboard text
        var newValue = e.originalEvent.clipboardData.getData('Text');

        // if new value is incorrect then stop event
        if (!allowedRegex.test(newValue)) {
            e.stopPropagation();
            return false;
        }
        if ($(this).val() != '' && $(this).val() != '0') shop2.update($(this).parents('.cartItem').data('id'), $(this).val());
        //cloneThis.calcSum($(this).parents('tr'));
    }).on('keypress', function(e) {
        // check input character
        return allowedCharRegex.test(e.key);
    }).on('focusout mouseout', function() {
        var id = $(this).parents('.cartItem').data('id');
        if ($(this).val() == '') $(this).val(shop2.products[id].amount);
        shop2.update(id, $(this).val());
    }).on('change keyup click', function(e) {
        // if ($(this).val() == '') $(this).val(1);
        var id = $(this).parents('.cartItem').data('id');
        if ($(this).val() != '' && $(this).val() != '0') shop2.update($(this).parents('.cartItem').data('id'), $(this).val());
        else if ($(this).val() == '0') $(this).val(shop2.products[id].amount);
    });
    
    shop2.delButton.on('click', function() {
        shop2.deleteProduct($(this).parents('.cartItem').data('id'));
    });

    /** Доставка */
    var orderForm = new CheckForms($('#orderForm'), '.button_submit');
    var addressBlock = shop2.container.find('.forma__field_address');

    shop2.deliveryInputs.on('change', function() {
        $(this).val() == 'none-delivery' ? addressBlock.hide().find('input').removeClass('requiredField errorField emptyField') : addressBlock.show().find('input').addClass('requiredField');
        orderForm.checkEmpty();
    });
}

function tabSteps() {
    let but = $('.cart__next, .cart__back__link a');
    let tabs = $('.cart');

    but.on('click', function(event) {
        let id = $(this).attr('href').substring(1);
        tabs.find('#'+id).trigger('click');
        event.preventDefault();
    });
}

let buy = function () {
    buy.closeBut = $('.fixCartBlock__close, #backCatalog');
    buy.fixCart = $('.fixCartBlock');
    buy.winProduct = $('.fixCartBlock__item');
    buy.cartIcon = $('#head .head__cart span');
    buy.urlCart = $('meta[name="cart:url"]').attr('content');

    buy.pageOptionCart = $('body').hasClass('option-cart') ? 1 : 0;

    buy.request();
    buy.events();
    buy.closeWindow();
}

buy.closeWindow = function () {
    let win = buy.winProduct.parent();

    buy.closeBut.on('click', function(event) {
        buy.winProduct.empty();
        buy.fixCart.removeClass('vis');
        event.preventDefault();
    });

    $(document).on('click touchstart', function(event) {
		let $target = $(event.target);
        if (!$target.is(win) && !win.find($target).length && !$target.is(buy.closeBut) && !buy.closeBut.find($target).length && buy.fixCart.hasClass('vis')) buy.closeBut.trigger('click');
    });
}

buy.constructWindow = function(item) {
    if ($('body').find('.getWindow').length) $('.mfp-close').trigger('click');

    buy.fixCart.addClass('vis');

    console.log(1)

    buy.winProduct.html('<img src='+item.src+' alt="" /><div class="fixCartBlock__item__title">'+item.name+'</div><div class="fixCartBlock__item__amount">Количество: '+item.quantity+'</div><div class="fixCartBlock__item__cost">'+item.price+' ₽</div>');
}

buy.buttonsChange = function (id) {
    $('body').find('[data-id='+id+']').each(function() {
        if ($(this).is('.tocart')) {
            $(this).parent().next().show();
            $(this).parent().hide()
        } else $(this).remove();
    });
}

buy.construct = function (data) {
    for (let key in data.cart) {
        let product = new Product(data.cart[key]);
        cart.addProduct(product);
    }
    // shop2.validateDelivery();
}

buy.constructBoxCart = function (data) {
    for (let key in data.cart) {
        let product = new Product(data.cart[key]);
        cart.addProduct(product);
        //buy.addProdInCart(product);
    }
    //buy.cartBox.find('.total .num').text(data.formatTotal + ' ' + buy.currency.name);
    //buy.delProdInMenu();
}

buy.request = function () {
    cart.pull(buy.urlCart, function (data) {
        if (buy.pageOptionCart) {
            buy.construct(data);
        } else {
            buy.constructBoxCart(data);
        }
        if (data.cart.length) buy.cartIcon.text(data.cart.length);
    });
}

buy.events = function () {
    let allowedRegex = /^[0-9]+$/;
    let allowedCharRegex = /[0-9]/;
    let tocartBlock = $('.catalog__item__tocart');
    let amountInp = tocartBlock.find('.itext');
    let but = $('.catalog__item .tocart, .catalog .catalog__item__btns__incart');
    let amount = 0;
    // let tocartIcon = $('#head .head__cart span');

    $('body').on('click', '.catalog__item__tocart .plus, .catalog__item__tocart .minus', function () {
        let val = parseInt($(this).parent().find('.itext').val());
        let amount = $(this).hasClass('plus') ? val + 1 : val - 1;
        if (amount < 1) amount = 1;
        $(this).parent().find('.itext').val(amount);
        //but.data('amount', amount);
    });

    $('body').on('paste', '.catalog__item__tocart .itext', function(e) {
        // handle only copy paste here
        // get clipboard text
        let newValue = e.originalEvent.clipboardData.getData('Text');

        // if new value is incorrect then stop event
        if (!allowedRegex.test(newValue)) {
            e.stopPropagation();
            return false;
        }
    }).on('keypress', '.catalog__item__tocart .itext', function(e) {
      // check input character
      return allowedCharRegex.test(e.key);
    }).on('change keyup click', '.catalog__item__tocart .itext', function(e) {
        if ($(this).val() == '0') $(this).val(but.data('amount'));
    }).on('focusout mouseout', '.catalog__item__tocart .itext', function() {
        if ($(this).val() == '') $(this).val(but.data('amount'));
    });

    $('body').on('click', '.catalog__item .tocart, .catalog .catalog__item__btns__incart', function(event) {
        let item = $(this);
        if ($(this).is('.tocart')) {
            amount = $(this).data('amount') * amountInp.val()*1;
            // let src = $(this).parents('.catalog__item').find('.gallery_catalog img').attr('src');
        } else {
            amount = $(this).data('amount');
            // let src = $(this).parents('.catalog__item').find('img').attr('src');
        }

        let product = new Product({
            id: item.data('id'),
            name: item.data('title'),
            price: item.data('price'),
            formatPrice: item.data('format-price'),
            quantity: amount,
            link: item.data('link'),
            src: item.data('src'),
            article: item.data('art'),
        });

        cart.addProduct(product, product.quantity);

        //let title = $(this).data('title');

        cart.push(buy.urlCart, cart.products, function (data) {
            buy.constructWindow(product);
            buy.cartIcon.text(data.cart.length);
            buy.buttonsChange(product.id);
        }, product);

        event.preventDefault();
    });
}

function viewProd () {
    $('.catalog__item__btns__more').magnificPopup({
		type: 'ajax',
		alignTop: true,
		overflowY: 'scroll' // as we know that popup content is tall we set scroll overflow by default to avoid jump
	});
}

function catalogItemGallery () {
    $('body').on('click', '.gallery_catalog a', function(event) {
        if (!$(this).hasClass('sel')) {
            $(this).parents('.gallery_catalog').find('.sel').removeClass('sel');
            $(this).addClass('sel');
            $(this).parents('.catalog__item').find('.catalog__item__image').attr('src', $(this).attr('href'));
            //image.attr('src', $(this).attr('href'));
        }
        event.preventDefault();
    });

    $('.catalog__item .gallery_catalog').owlCarousel({
        loop:true,
        margin:30,
        responsiveClass:true,
        responsive:{
            0:{
                items:2,
                nav:true
            },
            // 401:{
            //     items:2,
            //     nav:true
            // },
            320:{
                items:3,
                nav:true
            },
            480:{
                items:4,
                nav:true
            }/*,
            1000:{
                items:5,
                nav:true
            }*/
        }
    });
}

function accordion () {
    let link = $('.accordionBlock .accordionBlock__title a');

    link.on('click', function (event) {
        if ($(this).parent().hasClass('active') || $(this).parent().next().css('display') == 'block') {
            $(this).parent().removeClass('active').next('.accordionBlock__box').slideUp(300, 'linear');
        } else if (!$(this).parent().next().is('div:animated')) {
            // container.find('.active').removeClass('active').next('.accordionBlock__box').slideUp(300, 'linear');
            $(this).parent().addClass('active').next('.accordionBlock__box').slideDown(300, 'linear');
        }
        event.preventDefault();
    });

    //link.eq(0).click();
}

function overflowBlock () {
    let but = $('.overflowToggle a');

    but.on('click', function(event) {
        $(this).hasClass('overflowToggle__show') ? $(this).parent().addClass('overflowToggle_open').prev().removeClass('overflowBlock') : $(this).parent().removeClass('overflowToggle_open').prev().addClass('overflowBlock');
        event.preventDefault();
    });
}

function catalogColors () {
    let buttons = $('.catalog__item__colors span');
    let pageOptionCatalog =  $('body').hasClass('option-catalog') ? 1 : 0;
    let colorTitle = $('#colorTitle');
    let tocartBut = $('.tocart');

    buttons.on('click', function() {
        if (!$(this).hasClass('sel')) {
            if (pageOptionCatalog) {
                let image = $(this).parents('.catalog__item').find('img');
                let href = $(this).data('href');
                image.attr('src', $(this).data('src'));
                $(this).parents('.catalog__item').find('a').each(function() {
                    $(this).attr('href', href);
                });
                $(this).parents('.catalog__item').find('.catalog__item__btns__incart').data('color', $(this).data('color'));
            } else {
                colorTitle.text($(this).attr('title'));
                tocartBut.data('color', $(this).data('color'));
            }
            $(this).parent().find('.sel').removeClass('sel');
            $(this).addClass('sel');
        }
    });
}

function catalogRadioOptions () {
    let tocartBut = $('.tocart');
    let options = $('.catalog__item__radio input');

    options.on('change', function() {
        tocartBut.data($(this).attr('name'), $(this).val());
    });
}

function sendMsg () {
    let popup = $('.fixPopupMsg');
    let msg = $('.forma__sendMsg');
    let closeBut = $('.fixPopupMsg__close');
    
    let timeout;
    if (msg.find('div').length) {
        let classMsg = msg.find('div').attr('class');
        let textMsg = msg.find('div').text();
        popup.prepend('<div class="'+classMsg+'">'+textMsg+'</div>');
        popup.addClass('vis');
        timeout = setTimeout(function() {
            popup.animate({opacity : 'hide'}, 300, function() {
                $(this).removeClass('vis');
            });
        }, 5000);
        //popup.prepend('div class="'+classMsg+'"></div>')
    }

    closeBut.on('click', function() {
        clearTimeout(timeout);
        popup.animate({opacity : 'hide'}, 300, function() {
            $(this).removeClass('vis');
        })
    });
}

/********************************
********** Form Valid ***********
********************************/

/********************************
********** Form Valid ***********
********************************/

let CheckForms = function (form, button, options) {
    this.form = form;
    this.button = form.find(button);

    this.options = $.extend({}, CheckForms.Defaults, options);

    if (this.options.radio) this.radioChange();

    this.checkEmpty();
    this.submitForm();
    this.removeError();
}

CheckForms.Defaults = {
    errorClass: 'errorField',
    emptyClass: 'emptyField',
    radio: 0,
}

CheckForms.prototype.checkEmpty = function () {
    this.form.find('.requiredField').each(function () {
        if ($(this).val() == '' || $(this).val() == $(this).parent().find('label').text() || ($(this).is('[type = checkbox') && !$(this).is('input:checked'))) $(this).addClass('emptyField');
    });

    this.checkAllElements();
}

CheckForms.prototype.checkCorrect = function () {
    let patternEmail = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/;
    let patternDate = /^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/;
    this.form.find('.requiredField').each(function () {
        let item = $(this);
        let itemValue = item.val();
        let mailChecked = item.data('validate') != 'email' ? true : patternEmail.test(itemValue);
        let dateChecked = item.data('validate') != 'date' ? true : patternDate.test(itemValue);

        /**
         * @TODO:
         * 1. Get field type
         * 2. Check field by correct method (checkInput, checkRadio, checkCheckbox, checkEmail, checkDate)
         * 
         * checkDate by data-validate="date" + data-validatePattern="yyyy.mm.dd". Example: yyyy.mm.dd || dd.mm.yyyy
         **/ 
        if (!itemValue || !mailChecked || !dateChecked || itemValue == item.parent().find('label').text() || (item.is('[type = checkbox') && !item.is('input:checked'))) {
            item.addClass('errorField');
        }
        //if (item.is('[type = checkbox') && item.is('input:checked')) item.addClass('errorField');
    });

    this.checkAllElements();
}

CheckForms.prototype.removeError = function () {
    let patternEmail = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/;
    let patternDate = /^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/;
    //return patternEmail.test(value);

    this.form.on('keyup blur change', '.requiredField', function () {
        let item = $(this);
        let itemValue = item.val();
        let mailChecked = item.data('validate') != 'email' ? true : patternEmail.test(itemValue);
        let dateChecked = item.data('validate') != 'date' ? true : patternDate.test(itemValue);

        if (itemValue && itemValue != item.parent().find('label').text() && mailChecked && dateChecked) {
            item.removeClass('errorField emptyField');
        } else {
            itemValue == item.parent().find('label').text() ? item.addClass('emptyField') : item.addClass('errorField');
            if (!dateChecked) item.addClass('errorField');
        }

        if (item.is('[type = checkbox')) {
            item.is('input:checked') ? item.removeClass('errorField emptyField') : item.addClass('errorField');
        }

        let form = item.parents('form');
        let submitButton = form.find('.button_submit');
        if (!form.find('.emptyField').length && !form.find('.errorField').length) {
            submitButton.removeClass('button_disabled');
        } else {
            submitButton.addClass('button_disabled');
        }
    });
}

CheckForms.prototype.checkEmail = function (value) {
    let patternEmail = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/;
    return patternEmail.test(value);
}

CheckForms.prototype.checkAllElements = function () {
    if (!this.form.find('.emptyField').length && !this.form.find('.errorField').length) {
        this.button.removeClass('button_disabled');
    } else {
        this.button.addClass('button_disabled');
    }
}

CheckForms.prototype.submitForm = function () {
    this.button.click(function (event) {
        //this.checkEmpty();
        this.checkCorrect();
        event.preventDefault();
        if (!this.form.find('.emptyField').length && !this.form.find('.errorField').length) {
            this.form.submit();
        }
    }.bind(this));
}

function execCallForm() {
    new CheckForms($('#callForm'), '.button_submit');
}

function orderForm() {
    new CheckForms($('#orderForm'), '.button_submit');
}

function callForm() {
    $('.popupCallForm').magnificPopup({
		type: 'inline',
		preloader: false,
		focus: '#name',

		// When elemened is focused, some mobile browsers in some cases zoom in
		// It looks not nice, so we disable it:
		callbacks: {
			beforeOpen: function() {
				if($(window).width() < 700) {
					this.st.focus = false;
				} else {
					this.st.focus = '#name';
				}
			}
		}
    });
}

/********************************
******** END Form Valid *********
********************************/

function initMap() {
    let mapCenter = $('meta[name="contacts:map-center"]').attr('content');

    ymaps.ready(function () {
        let myMap = new ymaps.Map('map', {
            center: mapCenter.split(','),
            zoom: 17,
            controls: ['fullscreenControl', 'zoomControl', 'geolocationControl']
        });
        myMap.behaviors.disable(['scrollZoom', 'rightMouseButtonMagnifier', 'multiTouch']);

    	for (let key in places) {

    		placesExec = new ymaps.Placemark(
    		    places[key],
    		    {
    		        balloonContentHeader: '<div class="mcapt">' + places[key][2] + '</div>'
    		        // balloonContentBody: '<div class="mimage">' + places[key][3] + '</div>',
    		        // balloonContentFooter: '<div class="mbm">' + places[key][2] + '</div>'

    		    },
    		    {
                    preset: 'islands#dotIcon',
                    iconColor: '#EF798A'
    		    }
    		);
    		myMap.geoObjects.add(placesExec);
    	}
    });
}

function popupGal() {
    let options = {
        delegate: 'a',
        type: 'image',
        tLoading: 'Загружено #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            tCounter: '<span class="mfp-counter">%curr% из %total%</span>', // markup of counter
            preload: [0,1] // Will preload 0 - before current, and 1 after the current image
        },
        image: {
            tError: '<a href="%url%">Изображение #%curr%</a> не может быть загружено.',
            titleSrc: function(item) {
                return item.el.attr('title') + '<small></small>';
            }
        }
    };
    $('.popup-gallery').magnificPopup(options);
}

function ajaxSet() {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
}

function execShop () {
    if ($('meta[name="cart:url"]').length) {
        shop();
        // buy();
    }
}

x.exe['option-default'] = ['menuToggle()', 'placeholder()', 'fixPanel()', 'ajaxSet()', 'search()', 'execCallForm()', 'callForm()', 'catalogItemGallery()'];
x.exe['option-index'] = ['sendMsg()', 'totop()', 'carousel()', 'popupGal()', 'viewProd()', 'execShop()'];
x.exe['option-contacts'] = ['initMap()', 'execContactsForm()', 'popupFeedForm()'];
x.exe['option-content'] = ['popupGal()'];
x.exe['option-catalog'] = ['filtersCatalog()', 'viewProd()', 'catalogItemGallery()', 'execShop()', 'catalogColors()'];
x.exe['option-catalog-item'] = ['catalogItemGallery()', 'execShop()', 'viewProd()', 'accordion()', 'overflowBlock()', 'catalogColors()', 'catalogRadioOptions()'];
x.exe['option-cart'] = ['execShop()', 'tabSteps()'/*, 'orderForm()'*/];